<template>
  <div id="fileUpLoad" :class="['file-upload', customClass]">
    <div class="fu-title" v-if="!preview">
      <div class="fu-trigger">
        <slot style="display: inline-block" v-if="tempList.length < multiple" name="trigger" @click.stop="openFile">
        </slot>
        <van-button native-type="button" size="small" style="padding: 8px;margin-top:10px" v-if="!$slots.trigger && tempList.length < multiple" type="primary" @click.stop="openFile" icon="plus">选取文件</van-button>
        <van-button v-else type="primary" size="small" disabled>选取文件</van-button>
      </div>
      <div class="fu-count">
        <span v-text="tempList.length"></span>
        <span>/</span>
        <span v-text="multiple"></span>
      </div>
    </div>

    <!-- 提示框 -->
    <div class="fu-tips" v-if="!preview">
      <slot name="tip"></slot>
    </div>

    <!-- 上传文件组件 -->
    <div class="fu-body" :class="flagKey + '-onclick-input'">
      <van-uploader ref="fileUpLoad" :accept="accept" v-model="tempList" :disabled="preview" :preview-image="false" :before-read="beforeReadUpload" :after-read="afterReadUpload">
        <span class="el-van-uploader-span"></span>
      </van-uploader>
    </div>

    <!-- 文件回显 -->
    <div class="fu-list" :class="listType === 'list' ? 'fu-list-def' : 'fu-list-card'">
      <div v-for="file in tempList" :key="file.url">
        <van-notice-bar color="#165755" background="rgba(151, 239, 230, 0.2000)" class="notice-bar" mode="closeable">
          <template #left-icon>
            <svg-icon :icon-class="file.status === 'success' ? 'success' : 'info'" />
            <span style="margin-left:5px;width: 50%;!important" class="hidden-text">{{file.name.length < 18 ? file.name:file.name.substring(0,14) +"..."}}</span>
          </template>
          <template #right-icon>
            <span @click="handlePreview(file)">预览</span>
            <span style="margin-left:15px" @click="handleDownload(file)">下载</span>
            <span v-if="!preview" style="margin-left:15px" @click="handleRemove(file)">
              <van-icon name="cross" />
            </span>
          </template>
        </van-notice-bar>
      </div>
    </div>

  </div>
</template>

<script>
/**
 * 文件上传
 * @author GavinShaw
 * 本组件自定义双向绑定 调用组件 绑定v-model
 * props:
 *    fileList        Array   绑定v-model 格式为数组 [fileId, fileId, .....]
 *    disable         Boolean 是否允许编辑
 *    biz             String  业务代码  必要参数
 *    maxSize         Number  最大单文件大小 单位M 默认5M
 *    customClass     String  自定义类名 用于自定义样式
 *    customTrigger   Boolean 自定义Trigger组件
 *    preview         Boolean 预览模式 禁止上传/删除
 *    listType        String  文件上传列表显示方式 list | card
 *    multiple        Number  文件上穿个数限制 默认1 即单个上传
 *    showFileList    Boolean 是否显示文件列表
 *    accept          String  接受上传的文件类型
 *
 */

import { imageViewApi, fileDownloadApi } from "@/config/settings";
import { getType, isImg } from "./fileTypeMap";
import { remove, download, fileInfo, upload } from "@/api/system/storage";
import { ImagePreview } from "vant";

export default {
  name: "fileUpLoad",
  components: {},
  props: {
    fileList: {
      type: Array,
      require: true,
      default: () => {
        return [];
      },
    },
    miz: {
      type: String,
      require: true,
    },
    biz: {
      type: String,
      require: true,
    },
    maxSize: {
      type: Number,
      default: 5,
    },
    customClass: {
      type: String,
      default: "",
    },
    preview: {
      type: Boolean,
      default: false,
    },
    customTrigger: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Number,
      default: 1,
    },
    showFileList: {
      type: Boolean,
      default: true,
    },
    accept: {
      type: String,
    },
    autoUpload: {
      type: Boolean,
      default: true,
    },
    listType: {
      type: String,
      default: "list",
    },
    flagKey: {
      type: String,
      default: "def",
    },
  },
  model: {
    prop: "fileList",
    event: "checkDone",
  },
  watch: {
    tempList: {
      handler(nVal) {
        this.callFileList();
      },
    },
  },
  data() {
    return {
      fileDownloadApi,
      imageViewApi,
      uploadParams: { modules: this.miz, biz: this.biz },
      allowMultiple: this.multiple > 1,
      tempList: [],
    };
  },
  created() {
    this.$nextTick(() => {
      this.initInfo();
    });
  },
  beforeDestroy() {
    this.tempList = [];
  },
  methods: {
    getType,
    isImg,
    initInfo() {
      this.tempList = [];
      let that = this;
      if (this.fileList && this.fileList.length > 0) {
        fileInfo(this.fileList).then((res) => {
          if (res) {
            res.forEach((item) => {
              this.tempList.push({
                name: item.realName,
                url: item.id,
                status: "success",
                size: item.fileSize,
                type: item.type,
              });
            });
          }
        });
      } else {
        this.tempList = [];
      }
    },
    // 文件选取之前
    afterReadUpload(file) {
      console.log(file);
    },
    // 文件选取之后
    beforeReadUpload(file) {
      // 文件大小校验
      let allow = file.size / 1024 / 1024 < this.maxSize;
      if (!allow) {
        this.$notify({
          type: "danger",
          message: "文件最大允许" + this.maxSize + "M",
          duration: 2000,
        });
        return false;
      }

      this.uploadFile(file);
    },
    // 文件上传
    uploadFile(file) {
      this.uploadParams["file"] = file;
      upload(this.uploadParams)
        .then((res) => {
          this.uploadSuccess(res);
        })
        .catch((error) => {
          this.uploadError(file);
        });
    },
    // 上传文件后的回调
    callFileList() {
      let fileIds = [];
      this.tempList.forEach((item) => {
        if (item.url) {
          if (item.status === "success") fileIds.push(item.url);
        }
      });
      this.$emit("uploadResponse", fileIds, this.tempList);
    },
    // 打开文件选择
    openFile() {
      let upload = document.getElementsByClassName(
        this.flagKey + "-onclick-input"
      );
      for (let item of upload) {
        let dom = item.getElementsByClassName("van-uploader__input");
        dom[0].click();
      }
    },
    // 查看
    handlePreview(file) {
      if (file.type === "图片") {
        ImagePreview([imageViewApi + file.url]);
      } else {
        download(file.url);
      }
    },
    // 下载
    handleDownload(file) {
      download(file.url);
    },
    // 删除
    handleRemove(file) {
      let that = this;
      this.tempList.forEach((item, index) => {
        if (item.url === file.url) that.tempList.splice(index, 1);
      });
      if (file.status === "success") {
        remove(file.url).then((res) => {
          this.$notify({
            type: "warning",
            message: "文件已删除",
            duration: 1000,
          });
        });
      }
    },
    // 上传文件失败
    uploadError(file) {
      this.tempList.push({
        name: file.name,
        url: "",
        status: "info",
        size: file.size,
        type: "",
      });
      this.$notify({
        type: "danger",
        message: "上传失败！",
        duration: 1000,
      });
    },
    // 上传文件成功
    uploadSuccess(res) {
      this.tempList.push({
        name: res.realName,
        url: res.id,
        status: "success",
        size: res.size,
        type: res.type,
      });
      this.$notify({
        type: "success",
        message: "上传成功！",
        duration: 1000,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$title-height: 40px;
$tips-height: 50px;
$fu-list-card-size: 100px;

#fileUpLoad {
  .notice-bar {
    margin-bottom: 12px;
  }

  width: 100%;
  background-color: #fff;

  .fu-title {
    height: $title-height;
    padding: 0 30px 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    .fu-count {
      text-align: right;
      color: #dddddd;
      font-style: italic;
      width: $title-height;

      span {
        &:nth-child(1) {
          font-weight: bold;
          font-size: 20px;
        }

        &:nth-child(2) {
          margin: 0 3px;
        }
      }
    }

    .fu-trigger {
      width: calc(100% - #{$title-height});
    }
  }

  .fu-tips {
    margin: 10px 0;
    padding: 0 30px 0 17px;
    max-height: $tips-height;
    overflow: hidden;
  }

  .fu-body {
    width: 0;
    height: 0;
  }

  .fu-list {
    padding: 10px;
    max-height: 400px;
    overflow-y: scroll;

    .file-list-item {
      cursor: default;
      border-radius: 5px;
      position: relative;
    }
  }

  .fu-list-def {
    .file-list-item {
      position: relative;
      margin-bottom: 5px;
      padding: 5px;

      .fli-box {
        height: 32px;
        line-height: 32px;
        overflow: hidden;
        //line-height: 20px;
        position: relative;
        padding-left: 5px;

        .flc-type-icon {
          margin: 0 3px 0 8px;
        }
      }

      .fli-opt {
        background-color: #fff;
        position: absolute;
        padding: 0 10px;
        right: 0;
        height: 30px;
        line-height: 45px;
        top: 0;
        font-size: 17px;

        i {
          font-size: 17px;
          color: #606266;
          opacity: 0.4;
          transition: opacity 0.3s;
          cursor: pointer;

          &:nth-child(2) {
            margin: 0 10px;
          }

          &:hover {
            opacity: 1;
          }
        }
      }

      &:hover {
        color: #1890ff;
        background-color: #f6f8f9;

        .fli-opt {
          background-color: #f6f8f9 !important;
        }
      }
    }
  }

  .fu-list-card {
    .file-list-item {
      position: relative;
      display: inline-block;
      overflow: hidden;
      color: #606266;
      border: 1px solid #c0ccda;
      margin: 0 10px 10px 0;
      background-color: #fff;

      .fli-box {
        width: $fu-list-card-size;
        height: $fu-list-card-size;
        position: relative;

        .up-card-dot {
          position: absolute;
          right: 2px;
          top: 5px;
        }

        .flc-type-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .flc-type-icon {
          display: block;
          font-size: 36px;
          color: #606266;
          opacity: 0.6;
          position: relative;
          top: 20px;
        }

        .file-list-item-name {
          position: absolute;
          width: 100%;
          height: 20px;
          bottom: 0;
          display: block;
          line-height: 20px;
          overflow: hidden;
          font-size: 12px;
          background-color: #8c939d;
          color: #fff;
          text-align: center;
        }
      }

      .fli-opt {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        text-align: center;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.5);
        transition: opacity 0.3s;
        line-height: $fu-list-card-size;

        i {
          font-size: 20px;
          color: #fff;
          opacity: 0.7;
          cursor: pointer;
          margin: 0 5px;

          &:hover {
            opacity: 1;
          }
        }
      }

      &:hover {
        .fli-opt {
          opacity: 1;
        }
      }
    }
  }
}
</style>
