import request from '@/utils/request'

export async function add(data) {
  return request({
    url: 'api/approval',
    method: 'post',
    data
  })
}
export function list(params){
  return request({
    url: 'api/approval/list',
    method: 'get',
    params
  })
}
export function listGetOne(params){
  return request({
    url: 'api/approval/list/getOne',
    method: 'get',
    params
  })
}



