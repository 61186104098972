<template>
  <div>
    <van-cell-group>
      <van-cell style="padding-top: 17px;padding-bottom: 17px;">
        <template #title>
          <span style="color:rgba(35, 171, 114, 1)">{{form.year+form.teremName}}</span><br />
          <div class="custom-title hidden-text ">{{$route.query.schshiName}}</div>
        </template>
        <template #label>
          <div class="custom-centent  hidden-text">
            <svg-icon icon-class="date" size="15" /> 时间: {{form.createTime}}
          </div>
          <div class="custom-centent margin6 hidden-text">
            <svg-icon icon-class="unit" size="15" /> 等级: {{form.gradeName }}<span v-if="form.pbv"> （<span style="color:#ff976a;font-weight: 500;">¥{{form.pbv}}</span>） </span>
          </div>
        </template>
      </van-cell>
    </van-cell-group>

    <!-- 审核信息 -->
    <van-cell-group title="审核信息">
      <audit ref="audit" :app-params="{ coreKey: $route.query.id,  mkCode: 'JZ',coreCode:'INSPIRATION' , status: form.approvalStatus,level:3}" />
    </van-cell-group>
    <!-- 基本信息 -->
    <van-cell-group title="基本信息" id="Form">
      <van-field v-model="form.stuNo" readonly label="学号" />
      <van-field v-model="form.stuName" readonly label="姓名" />
      <van-field v-model="form.sex" readonly label="性别" />
      <van-field v-model="form.facultyName" label="院系" />
      <van-field v-model="form.specName" readonly label="专业" />
      <van-field v-model="form.className" readonly label="班级" />
      <van-field v-model="form.nationalitName" v-if="refsh" readonly label="民族" />
      <van-field v-model="form.politicalStatusName" v-if="refsh" readonly label="政治面貌" />
      <van-field v-model="form.bornTime" readonly label="出生日期" />
      <van-field v-model="form.admissionTime" readonly label="入学年月" />
      <van-field v-model="form.cardId" readonly label="身份证号" />
      <van-field v-model="form.phone" readonly label="联系电话" />
    </van-cell-group>
    <van-cell-group title="曾获奖励">
      <van-field v-model="form.honor" readonly rows="3" class="textarea-style" arrow-direction="" label="曾获何种奖励" type="textarea" />
    </van-cell-group>

    <van-cell-group title="家庭经济情况">
      <van-field readonly v-model="form.familyPopulation" label="家庭人口总数" />
      <van-field readonly v-model="form.familyIncomeCount" label="家庭月总收入" />
      <van-field readonly v-model="form.familyIncomeMonth" label="人均月收入" />
      <van-field readonly v-model="form.familyIncomeSource" label="收入来源" />
      <van-field readonly v-model="form.familyAddress" label="家庭住址" />
      <van-field readonly v-model="form.familyPostCode" label="邮政编码" />
    </van-cell-group>
    <!-- 学习成绩 -->
    <van-cell-group title="学习成绩">
      <van-field v-model="form.scoreGradesRanking" readonly label="成绩排名" />
      <van-field v-model="form.scoreObligatory" readonly label="必修课门数" />
      <van-field v-model="form.scoreObligatoryPass" readonly label="及格以上门数" />
      <van-field v-model="form.isComplexName" readonly label="是否综合考评" />
      <van-field v-if="form.isComplex" readonly v-model="form.complexGradesRanking" label="综合考评排名" />
    </van-cell-group>
    <!-- 申请理由 -->
    <van-cell-group title="申请理由">
      <van-field v-model="form.applyReason" readonly rows="3" class="textarea-style" arrow-direction="" label="申请理由" type="textarea" />
    </van-cell-group>

    <van-cell-group title="证明附件" class="upload-file" style="margin-bottom:65px;">
      <file-upload v-if="refsh" :preview="true" accept=".doc,.docx,.pdf,.xls,.xlsx,.png,.gif,.jpeg" v-model="form.fileId" miz="JZGL" biz="storage" :multiple="3" list-type="list" :auto-upload="true" :max-size="1">
        <span slot="tip" class="upload-tips">
          支持上传任意文件,文件最大1M
        </span>
      </file-upload>
    </van-cell-group>

    <!-- <div class="submit-button shadow-style">
      <div>
        <van-button round block type="warning" :loading="loading" @click="doDownload()">{{"下载"+(form.year?form.year+'年':"")+'国家励志奖学金申请'}}</van-button>
      </div>
    </div> -->
  </div>
</template>

<script>
import { getDtoById, downloadPdf } from "@/api/modules/award/inspirationJxj";
import { mapGetters } from "vuex";
import { statustag, parseTime } from "@/utils";
import fileUpload from "@/components/FileUpload";
import audit from "@/view/modules/common/audit";
import { simulationClickBlob } from "@/api/system/storage";

export default {
  name: "ExcellentBonusDetail",
  components: { fileUpload, audit },
  dicts: ["xj_shzt", "ctry_base_polit_stat", "nation_roman_alph_name"],
  data() {
    return {
      sexDict: [
        { label: "男", value: "1" },
        { label: "女", value: "2" },
      ],
      isNotDict: [
        { label: "是", value: true },
        { label: "否", value: false },
      ],
      refsh: true,
      loading: false,
      activeNames: ["0"],
      form: {
        id: "",
        stuName: null,
        stuNo: null,
        sex: null,
        bornTime: null,
        birthplace: null,
        nationality: null,
        politicalStatus: null,
        nationalitName: null,
        politicalStatusName: null,
        facultyNo: null,
        facultyName: null,
        specNo: null,
        specName: null,
        classNo: null,
        className: null,
        phone: null,
        tutorName: null,
        tutorNo: null,
        picture: null,
        honor: null,
        schoolPractice: null,
        gainAward: null,
        gainAwardAnnex: null,
        status: 0,
        fileId: [],
      },
    };
  },
  computed: {
    ...mapGetters(["user", "roles"]),
  },
  created() {
    //学生修改
    getDtoById(this.$route.query.id).then((res) => {
      this.form = res;
      // 强制刷新组件
      this.refsh = false;
      this.$nextTick(() => {
        this.form.sex = res.sex === "2" ? "女" : "男";
        this.form.isComplexName = res.isComplex ? "是" : "否";
        setTimeout(() => {
          this.form.nationalitName = this.codeConvertLabel(
            res.nationality,
            this.dict.nation_roman_alph_name
          );
          this.form.politicalStatusName = this.codeConvertLabel(
            res.politicalStatus,
            this.dict.ctry_base_polit_stat
          );
          this.refsh = true;
          // 业务ID 审批项目类型 审批业务类型 目前到审批状态
          this.$refs["audit"].init(
            res.id,
            "JZ",
            "INSPIRATION",
            parseInt(res.approvalStatus),
            3
          );
        }, 100);
      });
    });
  },
  methods: {
    statustag,
    parseTime,
    doDownload() {
      this.loading = true;
      downloadPdf(this.form.id)
        .then((result) => {
          simulationClickBlob(
            result,
            this.form.stuName + "-国家励志奖学金申请.pdf"
          );
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    codeConvertLabel(code, dicts) {
      return dicts
        .filter((item) => {
          return item.value + "" === code + "";
        })
        .map((item) => {
          return item.label;
        })
        .toString();
    },
    getSpztLabel(shzt) {
      let approvalStatus = parseInt(shzt);
      return this.dict.xj_shzt
        .filter((item) => {
          return parseInt(item.value) === approvalStatus;
        })
        .map((item) => {
          return item.label;
        })
        .toString();
    },
  },
};
</script>

<style lang="scss" scoped>
#Form {
  .van-cell {
    font-size: 15px;
    line-height: 32px;
    height: 50px;
  }
  .van-cell__left-icon,
  .van-cell__right-icon {
    line-height: 32px;
  }
  .textarea-style {
    height: 100% !important;
  }
}
.audit {
  top: 17px;
  right: 10px;
  border-radius: 0 13px 0 13px;
}
.custom-title {
  margin: 0 0 7px 0;
}
</style>