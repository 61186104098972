/**
 * 文件类型缩略图格式化
 * @author GavinShaw
 */

const TYPE_MAP = {
  'xls': 'file-excel-2-line',
  'xlsx': 'file-excel-2-line',
  'doc': 'file-word-2-line',
  'docx': 'file-word-2-line',
  'ppt': 'file-ppt-2-line',
  'pptx': 'file-ppt-2-line',
  'mp3': 'file-music-line',
  'mp4': 'file-music-line',
  'wav': 'file-music-line',
  'avi': 'file-music-line',
  'js': 'file-code-line',
  'vue': 'file-code-line',
  'css': 'file-code-line',
  'html': 'file-code-line',
  'htm': 'file-code-line',
  'md': 'markdown-line',
  'zip': 'file-zip-line',
  'rar': 'file-zip-line',
  'txt': 'file-text-line',
  'def': 'file-3-line',
  'jpg': 'image-2-line',
  'jpeg': 'image-2-line',
  'gif': 'image-2-line',
  'pdf': 'file-pdf-line',
  'bmp': 'image-2-line',
  'webp': 'image-2-line',
  'apng': 'image-2-line',
  'png': 'image-2-line',
  'ico': 'image-2-line',
  'tif': 'image-2-line'
}

const TYPE_COLOR_MAP = {
  'xls': '#67C23A',
  'xlsx': '#67C23A',
  'doc': '#409EFF',
  'docx': '#409EFF',
  'ppt': '#F56C6C',
  'pptx': '#F56C6C',
  'mp3': '#e6b422',
  'mp4': '#923a60',
  'wav': '#923a60',
  'avi': '#E6A23C',
  'js': '#597e82',
  'vue': '#c66f35',
  'css': '#597e82',
  'html': '#c66f35',
  'htm': '#c66f35',
  'md': '#405742',
  'zip': '#705438',
  'rar': '#705438',
  'txt': '#d3cbaf',
  'def': '#bcc7c7',
  'pdf': '#923a60',
  'jpg': '#cfb603',
  'jpeg': '#cfb603',
  'gif': '#cfb603',
  'bmp': '#cfb603',
  'webp': '#cfb603',
  'apng': '#cfb603',
  'png': '#cfb603',
  'ico': '#cfb603',
  'tif': '#cfb603'
}

const IMG_TYPE = ['jpg', 'gif', 'jpeg', 'webp', 'tif', 'bmp', 'apng', 'ico', 'png']

/**
 * 传入文件名 返回文件类型缩略图 未匹配返回默认 'file-3-line'
 * @param fileName
 */
export function getType(fileName){
  let cutIndex = fileName.lastIndexOf('.')
  let typeStr = fileName.substring(cutIndex+1)
  let typeIco = TYPE_MAP[typeStr.toLocaleLowerCase()]
  if(!typeIco) typeIco = TYPE_MAP["def"]
  return typeIco
}

export function getColor(fileName){
  let cutIndex = fileName.lastIndexOf('.')
  let typeStr = fileName.substring(cutIndex+1)
  let typeColor = TYPE_COLOR_MAP[typeStr.toLocaleLowerCase()]
  if(!typeColor) typeColor = TYPE_COLOR_MAP["def"]
  return typeColor
}

export function isImg(fileName){
  if(!fileName) return false
  let cutIndex = fileName.lastIndexOf('.')
  let typeStr = fileName.substring(cutIndex+1)
  return IMG_TYPE.indexOf(typeStr.toLocaleLowerCase()) > -1
}
